import { auth } from '@telekomconsalting/dex-guru-internal-sdk'
import { setSuppressEagerConnection } from '@telekomconsalting/react-dexguru-wallet'
import { SnackbarKey } from 'notistack'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App'
import ChatWidgetProvider from './components/ChatWidget/ChatWidgetProvider'
import { ConnectorsProvider } from './components/ConnectorsContext'
import ReloadBannerProvider from './components/ReloadBanner/ReloadBannerProvider'
import LazyWeb3ReactProvider from './components/Web3ReactProvider/LazyWeb3ReactProvider'
import AuthWrapper from './containers/AuthWrapper'
import StartupProvider from './containers/StartupProvider'
import WebsocketsWrapper from './containers/WebsocketsWrapper'
import { initApm } from './services/apmService'
import { store } from './services/reduxService'

// import(/* webpackPrefetch: true */ 'react-app-polyfill/stable')
import(/* webpackPrefetch: true */ 'rc-slider/assets/index.css').then()
import(/* webpackPrefetch: true */ './scss/index.scss').then()
import(/* webpackPrefetch: true */ 'simplebar/dist/simplebar.min.css').then()

localStorage.setItem('onboarding-intro-completed', 'true')
initApm()

const SnackbarProvider = React.lazy(
  () => import(/* webpackPrefetch: true */ './components/SnackbarProvider')
)

if (!auth?.hasToken()) {
  setSuppressEagerConnection(true)
}

// log env
// eslint-disable-next-line no-console
console.log('environment: ' + process.env.REACT_APP_ENVIRONMENT)

const rootElement = document.getElementById('root')
if (rootElement) {
  const root = rootElement && ReactDOMClient.createRoot(rootElement)
  root.render(
    <Provider store={store}>
      <ReloadBannerProvider />
      <StartupProvider>
        <LazyWeb3ReactProvider>
          <SnackbarProvider
            hideIconVariant
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              containerRoot: 'SnackbarPortal',
            }}
            content={(key: SnackbarKey, message: string | React.ReactNode): React.ReactNode => (
              <div id={`SnackbarItem-content--${key}`} className="SnackbarItem-content">
                {message}
              </div>
            )}>
            <ConnectorsProvider>
              <AuthWrapper>
                {process.env.REACT_APP_CHAT_ENABLED === 'true' ? (
                  <ChatWidgetProvider>
                    <WebsocketsWrapper>
                      <App />
                    </WebsocketsWrapper>
                  </ChatWidgetProvider>
                ) : (
                  <WebsocketsWrapper>
                    <App />
                  </WebsocketsWrapper>
                )}
              </AuthWrapper>
            </ConnectorsProvider>
          </SnackbarProvider>
        </LazyWeb3ReactProvider>
      </StartupProvider>
    </Provider>
  )
}
